<template>
  <main class="main" id="top">
    <div class="container" data-layout="container">
      <Navigation />
      <nav class="mt-3 mb-2" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li class="breadcrumb-item">
            <a href="/reservations">예약내역</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">에약내역 상세</li>
        </ol>
      </nav>
      <div class="content">
        <div class="d-flex border-bottom mt-3">
          <h3 class="mb-2">예약내역 상세</h3>
        </div>
        <div class="d-flex border-bottom p-3 mb-3">
          <div class="avatar avatar-4xl">
            <img class="rounded-soft" :src="imageUrl" alt="" />
          </div>
          <div class="flex-1 ms-3">
            <div class="d-flex flex-column">
              <h4 class="mb-2">{{ reservation.TITLE }}</h4>
              <p class="fs-0">{{ reservation.AREA }}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8">
            <div class="border-bottom p-3 mb-3">
              <h5 class="mb-3">결제정보</h5>
              <p class="fw-bold mb-2">주문번호 : {{ reservation.RESERVATION_NO }}</p>
              <p class="mb-2">주문일시 : {{ paymentTime }}</p>
              <p class="mb-2" v-show="reservation.STATUS == 4">취소일시 : {{ cancelTime }}</p>
              <p class="mb-2">결제방법 : {{ $func.getPaymentMethodLabel(reservation.PAYMENT_METHOD) }}</p>
              <p class="mb-2" v-show="reservation.PAYMENT_METHOD == 2">입금계좌 : {{reservation.ACCOUNT_NUMBER}} ({{reservation.ACCOUNT_HOLDER}})</p>
              <div class="mb-2">
                <span :class="`badge rounded-pill p-2 ps-3 pe-3 ${$func.getReservationStatusColor(parseInt(reservation.STATUS))}`"> {{ $func.getReservationStatusLabel(parseInt(reservation.STATUS)) }}</span>
              </div>
            </div>

            <div class="border-bottom p-3 mb-3" v-if="!isShowBookerEditor">
              <h5 class="mb-3">구매자 정보</h5>
              <p class="mb-2">이름 : {{ booker }}</p>
              <p class="mb-2">연락처 : {{ contact }}</p>
              <p class="mb-2">이메일 : {{ email }}</p>
              <p class="mb-2">특이사항 : {{ uniqueness }}</p>
              <div class="d-flex flex-row-reverse" v-if="isBookerInfoEditable">
                <button class="btn btn-falcon-primary rounded-pill btn-sm" type="button" @click="showBookerEditor()">구매자 정보 수정</button>
              </div>
            </div>
            <div class="border-bottom p-3 mb-3" v-else>
              <h5 class="mb-3">구매자 정보</h5>
              <div class="row mb-2">
                <label class="form-label col-sm-2" for="booker-name">이름</label>
                <div class="col-sm-10">
                  <input class="form-control" id="booker-name" type="text" ref="booker" placeholder="이름" v-model="booker" />
                </div>
              </div>
              <div class="row mb-2">
                <label class="form-label col-sm-2" for="booker-contact">연락처</label>
                <div class="col-sm-10">
                  <input class="form-control" id="booker-contact" type="tel" ref="contact" placeholder="연락처" v-model="contact" />
                </div>
              </div>
              <div class="row mb-2">
                <label class="form-label col-sm-2" for="booker-email">이메일</label>
                <div class="col-sm-10">
                  <input class="form-control" id="booker-email" type="email" ref="email" placeholder="이메일" v-model="email" />
                </div>
              </div>
              <div class="row mb-2">
                <label class="form-label col-sm-2" for="booker-email">특이사항</label>
                <div class="col-sm-10">
                  <textarea class="form-control" id="uniqueness" v-model="uniqueness" rows="3"></textarea>
                </div>
              </div>
              <div class="d-flex flex-row-reverse">
                <div>
                  <button class="btn btn-falcon-primary rounded-pill btn-sm me-2" type="button" @click="isShowBookerEditor = false">취소</button>
                  <button class="btn btn-falcon-primary rounded-pill btn-sm" type="button" @click="updateBookerInfo()">저장</button>
                </div>
              </div>
            </div>
            <div class="border-bottom p-3 mb-3" v-if="isShowRefundInfo && !isShowRefundEditor">
              <h5 class="mb-3">환불 정보</h5>
              <p class="mb-2">은행명 : {{ refundInfo.bank }}</p>
              <p class="mb-2">계좌번호 : {{ refundInfo.account }}</p>
              <p class="mb-2">예금주 : {{ refundInfo.account_user }}</p>
              <div class="d-flex flex-row-reverse" v-if="isRefundInfoEditable">
                <button class="btn btn-falcon-primary rounded-pill btn-sm" type="button" @click="showRefundEditor()">환불정보 수정</button>
              </div>
            </div>
            <div class="border-bottom p-3 mb-3" id="refundInfo" v-if="isShowRefundEditor">
              <h5 class="mb-3">환불 정보</h5>
              <div class="row mb-2">
                <label class="form-label col-sm-2" for="refundInfo_bank">은행명</label>
                <div class="col-sm-10">
                  <input class="form-control" id="refundInfo_bank" type="text" ref="bank" placeholder="은행명" v-model="refundInfo.bank" />
                </div>
              </div>
              <div class="row mb-2">
                <label class="form-label col-sm-2" for="refundInfo_account">계좌번호</label>
                <div class="col-sm-10">
                  <input class="form-control" id="refundInfo_account" type="text" ref="account" placeholder="계좌번호" v-model="refundInfo.account" />
                </div>
              </div>
              <div class="row mb-2">
                <label class="form-label col-sm-2" for="refundInfo_account_user">예금주</label>
                <div class="col-sm-10">
                  <input class="form-control" id="refundInfo_account_user" type="text" ref="account_user" placeholder="예금주" v-model="refundInfo.account_user" />
                </div>
              </div>
              <div class="d-flex flex-row-reverse" v-if="isRefundInfoEditable && isShowRefundEditor">
                <div>
                  <button class="btn btn-falcon-primary rounded-pill btn-sm me-2" type="button" @click="isShowRefundEditor = false">취소</button>
                  <button class="btn btn-falcon-primary rounded-pill btn-sm" type="button" @click="updateRefundInfo()">저장</button>
                </div>
              </div>
            </div>
            <div class="border-bottom p-3 mb-3" v-if="writableReview">
              <h5 class="mb-2">체험후기</h5>
              <div class="mb-2">
                <star-rating
                  :border-width="1"
                  border-color="#d8d8d8"
                  :rounded-corners="true"
                  :increment="1"
                  :star-size="15"
                  :read-only="false"
                  :show-rating="false"
                  :rating="programReview.rating"
                  :active-on-click="true"
                  :star-points="[23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46, 19, 31, 17]"
                  @rating-selected="setRating"
                >
                </star-rating>
              </div>
              <div class="mb-2">
                <textarea class="form-control" id="review" ref="review" v-model="programReview.review" rows="3"></textarea>
              </div>
              <div class="d-flex flex-row-reverse">
                <div>
                  <button class="btn btn-falcon-primary rounded-pill btn-sm" type="button" @click="saveProgramReview()">저장</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="ps-lg-2">
              <div class="mb-2">
                <h5>상품정보</h5>
              </div>
              <div class="border-dashed-bottom mb-2"></div>
              <div class="d-flex flex-column mb-2">
                <div class="mb-2 fs-0">{{ reservation.OPTION1_VALUE }}</div>
                <div class="fs-0">{{ reservation.OPTION2_VALUE }}</div>
              </div>
              <div class="row justify-content-between mb-3">
                <div class="col">
                  <h5 class="fw-bold d-inline">{{ reservation.PRICE ? reservation.PRICE.toLocaleString('ko-KR') : 0 }}원</h5>
                </div>
                <div class="col-auto">
                  <h5 class="fw-bold d-inline">× {{ reservation.QUANTITY }}</h5>
                </div>
              </div>
              <div class="border-dashed-bottom mb-2"></div>
              <div class="row justify-content-between mb-2">
                <div class="col">
                  <p class="fw-bold">총 결제 금액</p>
                </div>
                <div class="col-auto">
                  <h3 class="fw-bold text-primary">{{ reservation.PAYOUT ? reservation.PAYOUT.toLocaleString('ko-KR') : 0 }}원</h3>
                </div>
              </div>
              <div class="row justify-content-between">
                <div class="col" v-show="isShowCancelButton">
                  <button class="btn w-100 btn-primary rounded-pill" type="button" @click="cancelReservation()">예약취소</button>
                </div>
                <div class="col" v-show="isShowPayButton">
                  <button class="btn w-100 btn-primary rounded-pill" type="button" @click="payByCreditCard()">카드결제</button>
                </div>
                <div class="col" v-show="isShowCancelRefundButton">
                  <button class="btn w-100 btn-primary rounded-pill" type="button" @click="alertCancelRequestRefund()">예약취소 철회</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalDialog :modalId="'modalCancelReservation'" :title="'예약취소'" :message="'예약을 취소하시겠습니까?'" :okButton="'확인'" @DialogOk="requestCancelReservation"></ModalDialog>
        <ModalDialog :modalId="'modalCancelRequestRefund'" :title="'예약취소 철회'" :message="'예약취소를 철회하시겠습니까?'" :okButton="'확인'" @DialogOk="cancelRequestRefund"></ModalDialog>
      </div>
      <FooterContent />
    </div>
  </main>
</template>
<script>
// @ is an alias to /src
import { Modal } from 'bootstrap';
import moment from 'moment';
import Navigation from '@/components/common/Navigation.vue';
import FooterContent from '@/components/common/FooterContent.vue';
import ModalDialog from '@/components/common/ModalDialog.vue';
import StarRating from 'vue-star-rating';
import { Bootpay } from '@bootpay/client-js';
import config from '@/config';

export default {
  name: 'ProgramDetail',
  components: {
    Navigation,
    FooterContent,
    ModalDialog,
    StarRating,
  },
  data() {
    return {
      user: {},
      reservationNo: null,
      reservation: {},
      programReview: {
        id: -1,
        programId: -1,
        userUid: -1,
        userName: -1,
        rating: 5,
        review: '',
      },
      isPrivacy: false,
      isEndProduct: false,
      booker: '',
      contact: '',
      email: '',
      refundInfo: {},
      uniqueness: '',
      isShowBookerEditor: false,
      isShowRefundEditor: false,
    };
  },
  created() {
    //console.log('onCreated - reservationNo:' + this.$route.params.reservationNo);
    this.reservationNo = this.$route.params.reservationNo;
    //console.log('reservationNo:' + this.reservationNo);
    this.user = this.$func.getUser();
    this.getReservation();
  },
  computed: {
    imageUrl() {
      return '/api/resource/SS_PROGRAM/' + this.reservation.PROGRAM_ID + '.nolto';
    },
    paymentTime() {
      return moment(this.reservation.PAYMENT_TIME).format('YYYY-MM-DD HH:mm');
    },
    cancelTime() {
      return moment(this.reservation.CANCEL_TIME).format('YYYY-MM-DD HH:mm');
    },
    isShowCancelButton() {
      return this.reservation.STATUS == 1 || (this.reservation.STATUS == 2 && !this.isEndProduct);
    },
    isShowPayButton() {
      return this.reservation.PROGRAM_STATUS == 1 && this.reservation.STATUS == 1 && this.reservation.PAYMENT_METHOD == 2; //입금대기중이면 카드결제 가능
    },
    //예약취소 철회
    isShowCancelRefundButton() {
      return this.reservation.STATUS == 3;
    },
    //구매자 정보 수정버튼
    isBookerInfoEditable() {
      return this.reservation.STATUS != 4 && !this.isEndProduct;
    },
    //환불정보 제공여부
    isShowRefundInfo() {
      return (this.reservation.STATUS == 3 || this.reservation.STATUS == 4) && this.reservation.PAYMENT_METHOD == 2;
    },
    //환불정보 수정여부
    isRefundInfoEditable() {
      return this.reservation.STATUS == 3 && this.reservation.PAYMENT_METHOD == 2;
    },
    //후기 작성여부
    writableReview() {
      return this.reservation.STATUS == 2 && (this.isEndProduct || this.reservation.IS_COMPLETION);
    },
  },
  methods: {
    getReservation() {
      this.$http
        .get('/user-api/get-reservation.nolto', {
          params: {
            reservationNo: this.reservationNo,
            userUid: this.user.uid,
          },
        })
        .then((response) => {
          //console.log('getReservation-response:', response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.reservation = response.data.reservation;
          if (response.data.programReview) {
            this.programReview = response.data.programReview;
          }
          this.booker = this.reservation.BOOKER;
          this.contact = this.reservation.CONTACT;
          this.email = this.reservation.EMAIL;
          this.uniqueness = this.reservation.UNIQUENESS;
          if (this.reservation.PAYMENT_METHOD == 2 && this.reservation.STATUS == 4) {
            this.refundInfo = JSON.parse(this.reservation.CANCEL_DETAIL);
          }
          this.isEndProduct = false;
          if (this.reservation.PRODUCT_END_DATE) {
            const productEndDate = moment(this.reservation.PRODUCT_END_DATE); //SS_PROGRAM_PRODUCT.END_DATE 상품종료일자
            //console.log('productEndDate : ',productEndDate);
            const nowDate = moment();
            const diff = moment.duration(productEndDate.diff(nowDate)).asHours()
            //console.log('diff : ',diff)
            this.isEndProduct = diff < 0; //상품종료일이 지났는지 여부
          }
          //console.log('isEndProduct:', this.isEndProduct);
          this.isShowBookerEditor = false;
          this.isShowRefundEditor = false;
        })
        .catch((error) => {
          console.log(error);

        });
    },

    cancelReservation() {
      //console.log('cancelReservation');
      if (this.reservation.STATUS == 2) {
        if (this.isEndProduct) {
          this.$toast.warning('종료일자가 지난 상품은 예약취소가 불가능합니다.');
          return;
        }
        if (this.reservation.IS_COMPLETION) {
          this.$toast.warning('이미 사용하신 체험입니다.');
          return;
        }
        if (this.reservation.PROGRAM_STATUS != 1) {
          this.$toast.warning('마감된 오감체험은 예약취소가 불가능합니다.');
          return;
        }
        if (this.reservation.PAYMENT_METHOD == 2 && !this.isShowRefundEditor) {
          this.isShowRefundEditor = true;
          this.isShowBookerEditor = false;
          this.$toast.warning('예약취소를 위한 환불정보를 입력해주세요.');
          return;
        }

        if (this.isShowRefundEditor && !this.checkRefundInfo()) {
          this.$toast.warning('환불정보를 모두 입력해주세요.');
          return;
        }
      }

      var cancelReservationAlert = new Modal(document.getElementById('modalCancelReservation'), {
        keyboard: false,
      });
      cancelReservationAlert.show();
    },

    requestCancelReservation() {
      //console.log('requestCancelReservation');
      let params = {};
      params.reservationNo = this.reservation.RESERVATION_NO;
      params.userUid = this.user.uid;
      params.refundInfo = JSON.stringify(this.refundInfo);
      this.$http
        .post('/user-api/cancel-reservation.nolto', params)
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.$toast.success(response.data.message);
          this.getReservation();
        })
        .catch((error) => {
          console.log(error);

        });
    },

    alertCancelRequestRefund() {
      //console.log('alertCancelRequestRefund');
      var cancelRequestRefundAlert = new Modal(document.getElementById('modalCancelRequestRefund'), {
        keyboard: false,
      });
      cancelRequestRefundAlert.show();
    },

    cancelRequestRefund() {
      //console.log('cancelRequestRefund');
      let params = {};
      params.reservationNo = this.reservation.RESERVATION_NO;
      params.userUid = this.user.uid;
      this.$http
        .post('/user-api/cancel-request-refund.nolto', params)
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.$toast.success(response.data.message);
          this.getReservation();
        })
        .catch((error) => {
          console.log(error);

        });
    },

    showBookerEditor() {
      //console.log('showBookerEditor');
      this.isShowBookerEditor = true;
      this.isShowRefundEditor = false;
    },

    updateBookerInfo() {
      if (this.booker.length < 2 || this.booker.length > 10) {
        console.debug('booker.length', this.booker.length);
        this.$toast.warning('구매자 이름은 2~10자로 입력하세요.');
        this.$refs.booker.focus();
        return false;
      }

      if (!this.contact) {
        this.$toast.warning('연락처를 입력해주세요.');
        this.$refs.contact.focus();
        return false;
      }

      if (this.contact && !this.$func.validatePhoneNumber(this.contact)) {
        this.$toast.warning('연락처가 올바르지 않습니다.');
        this.$refs.contact.focus();
        return false;
      }

      if (this.email && !this.$func.validateEmail(this.email)) {
        this.$toast.warning('이메일이 올바르지 않습니다.');
        this.$refs.email.focus();
        return false;
      }

      let params = {};
      params.reservationNo = this.reservation.RESERVATION_NO;
      params.userUid = this.user.uid;
      params.booker = this.booker;
      params.contact = this.contact;
      params.email = this.email;
      params.uniqueness = this.uniqueness;

      this.$http
        .post('/user-api/update-booker-info.nolto', params)
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.$toast.success(response.data.message);
          this.getReservation();
        })
        .catch((error) => {
          console.log(error);

        });
    },
    showRefundEditor() {
      this.isShowRefundEditor = true;
      this.isShowBookerEditor = false;
    },

    checkRefundInfo() {
      //console.log('checkRefundInfo');
      if (!this.refundInfo.bank) {
        this.$refs.bank.focus();
        return false;
      }
      if (!this.refundInfo.account) {
        this.$refs.account.focus();
        return false;
      }
      if (!this.refundInfo.account_user) {
        this.$refs.account_user.focus();
        return false;
      }
      return true;
    },

    updateRefundInfo() {
      //console.log('updateRefundInfo');
      if (this.isShowRefundEditor && !this.checkRefundInfo()) {
        this.$toast.warning('환불정보를 모두 입력해주세요.');
        return;
      }

      let params = {};
      params.reservationNo = this.reservation.RESERVATION_NO;
      params.userUid = this.user.uid;
      params.refundInfo = JSON.stringify(this.refundInfo);

      this.$http
        .post('/user-api/update-refund-info.nolto', params)
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.$toast.success(response.data.message);
          this.getReservation();
        })
        .catch((error) => {
          console.log(error);

        });
    },
    setRating(rating) {
      this.programReview.rating = rating;
    },
    saveProgramReview() {
      // todo 구매 프로그램 목록 api 구현후 구현하기
      //console.log('saveProgramReview');
      if (this.programReview.review.length < 5 || this.programReview.review.length > 200) {
        this.$toast.warning('체험후기는 5자이상 200자 이내로 작성해주세요.');
        this.$refs.review.focus();
        return false;
      }

      this.programReview.programId = this.reservation.PROGRAM_ID;
      this.programReview.userUid = this.user.uid;
      this.programReview.userName = this.user.name;

      this.$http
        .post('/user-api/save-program-review.nolto', this.programReview)
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.$toast.success(response.data.message);
          this.getReservation();
        })
        .catch((error) => {
          console.log(error);

        });
    },
    payByCreditCard() {
      try {
        let orderName = this.reservation.TITLE + '(';
        if (this.reservation.OPTION1_VALUE) {
          orderName = orderName + this.reservation.OPTION1_VALUE;
        }
        if (this.reservation.OPTION2_VALUE) {
          orderName = orderName + ' : ' + this.reservation.OPTION2_VALUE;
        }
        orderName = orderName + ')';

        Bootpay.requestPayment({
          application_id: process.env.VUE_APP_BOOTPAY_KEY, //config.bootPayJavascriptKey,
          price: this.reservation.PAYOUT,
          order_name: orderName,
          order_id: this.$func.getUUID(),
          // order_id: 'TEST_ORDER_ID',
          pg: 'payapp',
          method: 'card',
          tax_free: 0,
          user: {
            id: this.user.uid,
            username: this.reservation.BOOKER,
            phone: this.reservation.CONTACT,
            email: this.reservation.EMAIL,
          },
          items: [
            {
              id: this.reservation.PROGRAM_PRODUCT_ID,
              name: orderName,
              qty: this.reservation.QUANTITY,
              price: this.reservation.PRICE,
            },
          ],
          extra: {
            open_type: 'popup', //redirect, iframe, popup
            card_quota: '0,2,3',
            escrow: false,
          },
        }).then((response) => {
          //console.log('response:', response);
          this.payReservation(response.data);
        });
      } catch (e) {
        // 결제 진행중 오류 발생
        // e.error_code - 부트페이 오류 코드
        // e.pg_error_code - PG 오류 코드
        // e.message - 오류 내용
        //console.log(e.message);
        switch (e.event) {
          case 'cancel':
            // 사용자가 결제창을 닫을때 호출
            //console.log(e.message);
            break;
          case 'error':
            // 결제 승인 중 오류 발생시 호출
            //console.log(e.error_code);
            break;
        }
      }
    },

    payReservation(receiptDetail) {
      let userReservation = {};
      userReservation.reservationNo = this.reservation.RESERVATION_NO;
      userReservation.userUid = this.reservation.USER_UID;
      userReservation.paymentMethod = 1;
      userReservation.paymentTime = moment(receiptDetail.purchased_at).format('YYYY-MM-DD HH:mm:ss');
      userReservation.receiptId = receiptDetail.receipt_id;
      userReservation.receiptDetail = JSON.stringify(receiptDetail);
      //console.log('payReservation :', userReservation);
      this.$http
        .post('/user-api/pay-reservation.nolto',userReservation)
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.$toast.success(response.data.message);
          this.getReservation();
        })
        .catch((error) => {
          console.log(error);

        });
    },
  },
};
</script>
